import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Col, Row} from "reactstrap";
import axios from "axios";
import Icons from "../components/Icons";
import {Helmet} from 'react-helmet';
import {ShopInfoProps, withShopInfo} from "../contexts/ShopInfoContext";
import {generateBaseUrl} from "../utils";
import 'lazysizes';

type IndexProps = WithTranslation & ShopInfoProps & {
  context: {
    categories: Record<string, any>
  }
}

class Index extends React.Component<IndexProps, any> {
  private lng: string;

  constructor(props: IndexProps) {
    super(props);
    this.lng = this.props.i18n.language;

    this.state = {
      texte1: '',
      texte2: ''
    };
  }

  componentDidMount(): void {
    this.loadHtml('1');
    this.loadHtml('2');
  }


  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.i18n.language !== prevProps.i18n.language || this.props.i18n.language !== this.lng) {
      this.lng = this.props.i18n.language;
      this.loadHtml('1');
      this.loadHtml('2');
      return true;
    }
  }

  private loadHtml(tag: string) {
    axios.get(`${window.location.origin}/html/${this.lng}/homePage_${tag}.html`)
      .then((res) => {
        if (tag === '1') {
          this.setState({texte1: res.data});
        } else if (tag === '2') {
          this.setState({texte2: res.data});
        }
      }).catch((err) => {
      console.error(err);
    });
  }

  render() {

    const baseUrl = generateBaseUrl(this.props.shopInfo.env);
    const canonical = `${baseUrl}/${this.props.i18n.language}/`;
    const canonicalFR = `${baseUrl}/fr/`;
    const canonicalEN = `${baseUrl}/en/`;

    const renderHead = () => {
      return (
        <Helmet>
          <title>{this.props.t('index_title')}</title>
          <meta name="description" content={this.props.t('index_meta_description')}/>
          <meta name="twitter:card" content="summary"/>
          <meta name="twitter:site" content="@DuckTransfer"/>
          <meta property="twitter:description" content={this.props.t('index_meta_description')}/>
          <meta property="twitter:title" content={this.props.t('index_title')}/>
          <meta property="twitter:image" content="https://www.ducktransfer.com/images/logo-ducktransfer.jpg"/>
          <meta property="og:site_name" content="DuckTransfer"/>
          <meta property="og:description" content={this.props.t('index_meta_description')}/>
          <meta property="og:title" content={this.props.t('index_title')}/>
          <meta property="og:image" content="https://www.ducktransfer.com/images/logo-ducktransfer.jpg"/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={canonical}/>
          <link rel="canonical" href={canonical}/>
          <link rel="alternate" href={canonicalFR} hrefLang="x-default"/>
          <link rel="alternate" href={canonicalFR} hrefLang="fr"/>
          <link rel="alternate" href={canonicalEN} hrefLang="en"/>
          <link rel="preload" href={"/images/home-big-1.jpg"} as={'image'}/>
        </Helmet>
      );
    };

    const categories = Object.values(this.props.context.categories);

    const transferCategory = categories.find((elt) => elt.IdCategorie === 443);
    const consumablesCategory = categories.find((elt) => elt.IdCategorie === 445);
    const hotPressesCategory = categories.find((elt) => elt.IdCategorie === 446);
    const cuttingCategory = categories.find((elt) => elt.IdCategorie === 447);

    let transferLink = transferCategory?.oneProduct?.[this.props.i18n.language] || '';
    if(transferLink === '' && transferCategory?.links?.[this.props.i18n.language] ) {
      transferLink = transferCategory?.links?.[this.props.i18n.language];
    }
    const consumablesLink = consumablesCategory?.links?.[this.props.i18n.language] || '';
    const hotPressesLink = hotPressesCategory?.links?.[this.props.i18n.language] || '';
    const cuttingLink = cuttingCategory?.links?.[this.props.i18n.language] || '';

    const consumablesName = consumablesCategory?.name?.[this.props.i18n.language] || this.props.t('index_h2_2');
    const hotPressesName = hotPressesLink?.name?.[this.props.i18n.language] || this.props.t('index_h2_3');
    const cuttingName = cuttingLink?.name?.[this.props.i18n.language] || this.props.t('index_h2_4');

    return (
      <>
        {renderHead()}
        <div className={'container-fluid '}>
          <div className={'container-fluid container-fluid-dt pt-3 '}>
            <h1>{this.props.t('index_h1')}</h1>
            <Row className={'mb-0 mb-lg-4 mb-xl-5'}>
              <Col className={'home-big'} xs={{size: 12}} lg={{size: 6}}>
                <div className={'position-relative home-big-1 h-100'}>
                  <div className={"white home-big-wrapper"}>
                    <h2 className={'home-big-titre bold font30'}>
                      {this.props.t('index_h2_1')}
                    </h2>
                    <div className={'home-big-description font20 py-2'}>
                      {this.props.t('description_home_1')}
                    </div>
                    <div className={'home-big-btn'}>
                      <a className={'btn btn-dt-secondary white border-white mt-4 text-uppercase '}
                         href={transferLink}>
                        {this.props.t('order_your_transfers')}
                      </a>
                    </div>
                  </div>
                </div>
              </Col>

              <Col className={'home-big'} xs={{size: 12}} lg={{size: 6}}>
                <div className={'position-relative home-big-2 h-100'}>
                  <div className={"white home-big-wrapper"}>
                    <h2 className={'home-big-titre bold font30'}>
                      {consumablesName}
                    </h2>
                    <div className={'home-big-description font20 py-2'}>
                      {this.props.t('description_home_2')}
                    </div>
                    <div className={'home-big-btn'}>
                      <a className={'btn btn-dt-secondary  white border-white mt-4 text-uppercase '}
                         href={consumablesLink}>
                        {this.props.t('see_all_consumables')}
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className={'mt-lg-3'}>
              <Col className={'home-big'} xs={{size: 12}} lg={{size: 6}}>
                <div className={'position-relative home-big-3 outline-1px h-100'}>
                  <div className={"home-big-wrapper home-big-wrapper-light"}>
                    <h2 className={'home-big-titre bold font30'}>
                      {hotPressesName}
                    </h2>
                    <div className={'home-big-description font20 py-2'}>
                      {this.props.t('description_home_3')}
                    </div>
                    <div className={'home-big-btn'}>
                      <a className={'btn btn-dt-secondary mt-4 text-uppercase'} href={hotPressesLink}>
                        {this.props.t('learn_more_about_hot_presses')}
                      </a>
                    </div>
                  </div>
                </div>
              </Col>

              <Col className={'home-big '} xs={{size: 12}} lg={{size: 6}}>
                <div className={'position-relative home-big-4 outline-1px h-100'}>
                  <div className={"home-big-wrapper  home-big-wrapper-light"}>
                    <h2 className={'home-big-titre bold font30'}>
                      {cuttingName}
                    </h2>
                    <div className={'home-big-description font20 py-2'}>
                      {this.props.t('description_home_4')}
                    </div>
                    <div className={'home-big-btn'}>
                      <a className={'btn btn-dt-secondary mt-4 text-uppercase '} href={cuttingLink}>
                        {this.props.t('discover_the_cutting_material')}
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className={'container-fluid pt-5 container-fluid-dt  '}>
          <Row className={'font20 py-5  '}>
            <Col className={'text-center'} xs={{size: 12}} md={{size: 8, offset: 2}}>
              <Icons icon="quotation_marks" size={20}
                     className={'mr-1 align-baseline grey'}/> {this.props.t('quote_part_one')}<br/><span
              className={'bold'}>{this.props.t('quote_part_two')}</span>
            </Col>
          </Row>

          <Row className={'mt-4 mb-5 pb-5 text-center'}>
            <Col xs={{size: 12}}>
              <Row className={'my-5 justify-content-center'}>
                <Col xs={{size: 12}} sm={{size: 12}} md={{size: 4}} className={'mb-4'}>
                  <img className={'img-fluid lazyload'} data-src={'/images/home-1.jpg'} width='550' height='290' alt={this.props.t('home1_img')}/>
                </Col>
                <Col xs={{size: 12}} sm={{size: 12}} md={{size: 4}} className={''}>
                  <img className={'img-fluid lazyload'} data-src={'/images/home-2.jpg'}  width='550' height='290' alt={this.props.t('home2_img')}/>
                </Col>
                <Col xs={{size: 12}} sm={{size: 12}} md={{size: 4}} className={''}>
                  <img className={'img-fluid lazyload'} data-src={'/images/home-3.jpg'}  width='550' height='290' alt={this.props.t('home3_img')}/>
                </Col>
                <Col xs={{size: 12}} sm={{size: 12}} md={{size: 4}} className={''}>
                  <img className={'img-fluid lazyload'} data-src={'/images/home-4.jpg'}  width='550' height='290' alt={this.props.t('home4_img')}/>
                </Col>
                <Col xs={{size: 12}} sm={{size: 12}} md={{size: 4}} className={''}>
                  <img className={'img-fluid lazyload'} data-src={'/images/home-5.jpg'}  width='550' height='290' alt={this.props.t('home5_img')}/>
                </Col>
                <Col xs={{size: 12}} sm={{size: 12}} md={{size: 4}} className={''}>
                  <img className={'img-fluid lazyload'} data-src={'/images/home-6.jpg'}  width='550' height='290' alt={this.props.t('home6_img')}/>
                </Col>
              </Row>
            </Col>
            <Col xs={{size: 12}} lg={{size: 8, offset: 2}}>
              <div className={'font20 text-left mt-5 mb-5 pl-4 customBorder'}
                   dangerouslySetInnerHTML={{__html: this.state.texte1}}></div>
            </Col>
          </Row>

        </div>
      </>
    );
  }
}

export default withTranslation('index')(withShopInfo(Index));
